.lazy-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text {
  padding: 11px;
}

.filter_icon {
  border: 1px solid #BABFC3;
  padding: 4px;
  margin-top: 2px;
  border-radius: 3px;
  margin-left: 4px;
}

.filter_icon .Polaris-Icon__Svg {
  font-weight: 600;
}

.dashboard_datatable .Polaris-DataTable__Table thead tr th:first-child {
  width: 200px;
}

.dashboard_datatable .Polaris-DataTable__Table tbody tr td:last-child {
  width: 200px;
}

.dash_banner {
  display: flex;
  /* justify-content: space-between; */
}

.dashboard_datatable .Polaris-DataTable__Table tbody tr th:first-child {
  color: #2C6ECB;
  cursor: pointer;
}

.dashboard_datatable .Polaris-DataTable__Table tbody tr th p {
  font-weight: 500;
}

.product_table .Polaris-DataTable__Table tbody tr td:first-child p {
  color: #2C6ECB;
  cursor: pointer;
}

.apply_btn {
  margin-left: 5px;
}

.dash_rangline .Polaris-LegacyCard .Polaris-LegacyCard__Section {
  padding-top: 10px !important;
}

.dash_rangline .Polaris-RangeSlider-SingleThumb__Input {
  color: #449DA7 !important;
}

.dash_datatable_maincls .Polaris-LegacyCard .Polaris-LegacyCard__Header {
  padding-bottom: 20px;
}

.star_icon {
  padding-left: 65%;
}

.dash_rangline span {
  display: inline-block;
  padding-bottom: 10px;
}

.rang_limit {
  display: flex;
}

.rang_limit p {
  margin-top: -6px;
  padding-left: 5px;
}

.Polaris-Tabs__NewTab button {
  display: none;
}

.Polaris-IndexFilters-FilterButton {
  margin-left: 5px !important;
}

.setting_button {
  display: flex;
  justify-content: space-between;
}

.setting_button p {
  padding-top: 7px;
}

.theme_icon {
  display: flex;
}

.theme_icon .Polaris-Icon {
  margin: 0 !important;
}

.theme_icon_div {
  border-radius: 50%;
  padding: 11px;
  padding-top: 11px;
  background-color: #e3e3e3;
  width: 40px;
  height: 40px;
  align-items: center;
  color: #0000;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 1rem;
}

.product_radio {
  display: flex;
  gap: 40px;
}

.product_lheader .Polaris-LegacyCard__Header {
  padding-bottom: 1.25rem !important;
}

.product_ava .Polaris-ButtonGroup {
  justify-content: end;
}

/* .product_ava .Polaris-ButtonGroup .Polaris-Button {
  border: none;
  box-shadow: none;
} */

.product_ava .Polaris-DataTable__Cell--header {
  font-weight: 600;
}

.product_ava .Polaris-LegacyCard__Section {
  padding-top: 6px;
}

.product_ava thead .Polaris-DataTable__Cell--numeric {
  padding-right: 71px !important;
}

.product_card .Polaris-LegacyCard {
  box-shadow: none;
}

.product_card .Polaris-LegacyCard__Section {
  text-align: center;
}

.product_btn .Polaris-Button:nth-child(1),
.product_btn .Polaris-Button:nth-child(3) {
  border: 0;
  box-shadow: none;
}

.product_btn {
  padding-top: 25px;
}

.product_card .Polaris-ButtonGroup {
  justify-content: center;
  padding: 25px 0 29px 0;
}

.product_card .Polaris-Text--headingMd {
  padding: 25px 0 0 0;
}

.product_card .Polaris-Divider {
  width: 30px;
  display: inline-block;
}

.product_card {
  text-align: center;
}

/* .product_card .Polaris-Button--fullWidth {
  background-color: black;
  color: white;
  padding: 18px;
  border-radius: 11px;
}

.product_card .Polaris-Button--fullWidth:hover {
  background-color: black;
} */

.product_tab .Polaris-LegacyCard__Section {
  padding: 0 !important;
}

.product_tab .Polaris-FormLayout {
  padding: 1.25rem;
}

.product_confirmation .Polaris-FormLayout {
  padding: 0px;
  padding-top: 1px;
}

/* .product_tab .Polaris-TextField__Prefix{
  height: 20px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  padding: 0;
  width: 20px;
  } */
.Polaris-ColorPicker__MainColor {
  position: absolute !important;
  overflow: auto !important;
  width: 20px !important;
  height: 20px !important;
}

.Polaris-ColorPicker__HuePicker {
  position: absolute !important;
  overflow: auto !important;
  margin-left: 0;
  border-radius: 0px;
}

.custom-input-style1 .Polaris-TextField__Prefix {
  margin-right: 10px;
  color: red;
}


.custom-input-style1 .Polaris-TextField__Prefix {
  border-right: 1px solid #BBBFC3;
  height: 35px;
  padding-top: 1px;
  padding-right: 6px;
  margin-left: 6px;
}

.custom-input-style1 .Polaris-TextField__Prefix .Polaris-TextField__Input {
  width: 30px;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  padding: 0;

}

.custom-input-style1 .Polaris-TextField__Input:focus.Polaris-TextField__Backdrop::after {
  box-shadow: unset;
  outline: unset;
}

.custom-input-style1 .Polaris-TextField__Prefix .Polaris-TextField {
  border: none;
}

.action_form {
  margin-top: 10px;
}

.custom-input-style1 .Polaris-TextField__Prefix .Polaris-TextField__Backdrop {
  border: none;
}

.pb-3 {
  padding-bottom: 15px;
}

.display-email {
  display: flex;
}

.display-email svg {
  margin-top: 5px;
  margin-right: 5px
}

.setting-main-item button {
  text-decoration: none !important;
}

.setting-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
}

.setting-main-item {
  padding: 1em;
}

@media (max-width: 767px) {
  .Polaris-LegacyCard__Section>.setting-items {
    display: flex;
    flex-direction: column;
  }
}

.setting-helptext {
  color: #6D7175;
}

.preview-email {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  position: relative !important;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.event-none {
  pointer-events: none !important;
  opacity: 0.5;
}

.email-edit-btn {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
}

.test-email {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.test-email-btn {
  margin-top: 24px;
  padding-left: .5rem !important;
}

.save-btn {
  display: flex;
  justify-content: flex-end !important;
}

.signup-settings>.Polaris-LegacyCard__Section {
  padding: 0 !important;
  padding-top: 15px !important;
  border: 0 !important;
}

.color-settings .Polaris-TextField:focus-within>.Polaris-TextField__Input~.Polaris-TextField__Backdrop::after {
  box-shadow: none !important;
}

.preview-divider {
  padding: 5px 0 14px 0;
}

.pricing-plan-main .Polaris-Layout__Section {
  flex: 1 1 var(--pg-layout-width-one-third-width-base);
  min-width: 0;
  width: 328px;
}

.pricing-text h3:nth-child(1) {
  color: #6D7175;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 10px;
}

.pricing-text p {
  padding-top: 5px;
  color: #008060;
}

.price-upgrade {
  margin-top: 20px;
}

.plan-dtl .Polaris-List {
  min-height: 124px;
}

/* .price-free .Polaris-LegacyCard__Section:first-child {
  padding-bottom: 45px;
} */

.pricing-emails .Polaris-Layout__Section,
.pricing-month .Polaris-Layout__Section {
  flex: 1 1 var(--pg-layout-width-one-third-width-base);
  padding-top: 20px;
  min-width: 0;
  width: 800px;
  margin: 0 auto;
}

.pricing-month {
  text-align: center;
}

.num-email {
  padding-top: 20px;
}

.display-flex {
  display: flex;
}

.amount-div {
  width: 100%;
  text-align: right;
}

.pt-2 {
  padding-top: 10px;
}

.pb-2 {
  padding-bottom: 10px;
}

.plan-div div {
  padding-top: 2px;
}

.plan-div {
  width: 171px;
  height: 42px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px #0000000D;
  border: 1px solid #BABFC3;
}

.plan-div .Polaris-Button:nth-child(2) {
  padding-right: 20px;
  padding-left: 20px;
}

.plan-div .Polaris-Button:nth-child(1) {
  border: 0;
  background-color: #fff;
  box-shadow: none;
}

.badge-div {
  padding-top: 10px;
  padding-left: 10px;
}

.wrap {
  text-align: center;
  width: 100%;
}

.left,
.right {
  display: inline-block;
}

.nodata-found {
  text-align: center;
  color: #1f2124;
}

.preview-email-div {
  width: 400%;
  height: 300%;
  border-radius: 5px;
  pointer-events: none !important;
  overflow: hidden;
  margin-bottom: -440px;
  -webkit-transform: scale(.50);
  -ms-transform: scale(.25);
  transform: scale(0.25);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.main-preview-div .Polaris-LegacyCard .Polaris-LegacyCard__Section {
  height: 240.641px;
}

/* .btn-grp {
  padding-top: 10px;
} */

.btn-grp .Polaris-ButtonGroup {
  justify-content: end;
}

.btn-grp .Polaris-ButtonGroup .Polaris-Button {
  border: none !important;
  box-shadow: none !important;
}

.feature_div .Polaris-FormLayout {
  margin-top: 0px;
}

.feature_div .Polaris-LegacyCard__Section {
  padding-top: 5px;
}

.upload_icon_div {
  flex: 1;
}

.upload_icon_div button svg {
  fill: none;
  vertical-align: middle;
}

.noti_div {
  margin-left: 5px;
}

.select-div {
  float: left;
}

.search-div,
.comment-div {
  float: right;
}

.clear {
  clear: both;
}

.pt-15 {
  padding-top: 15px;
}

.list-li {
  margin-top: 1rem !important;
}

.vote-btn {
  background: #F6F6F7;
  padding: 5px 12px;
  border-radius: 8px;
  color: #969495;
  font-weight: 500 !important;
  display: inline-block;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}

.vote-num {
  text-align: center !important;
}

.mb-2 {
  margin-bottom: 5px;
}

.comment-num {
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.voters-li {
  margin-top: 10px;
}

.req-title .Polaris-Text--root {
  line-height: 50px;
}

.pagination nav div {
  justify-content: center;
}

.pagination nav {
  padding: 10px;
}

.spinnerDiv {
  justify-content: center;
  display: flex;
}

.main-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
}

.customer-badge .Polaris-Badge {
  background: #affebf;
  color: #014b40;
}

@media only screen and (max-width: 320px) {
  .preview-email-div {
    margin-bottom: -450px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .preview-email-div {
    margin-bottom: -450px;
  }
}

.email_activity .Polaris-LegacyCard {
  overflow: visible;
}

.uploadfile-div {
  padding-left: 5px;
  margin-top: 10px;
  word-break: break-all;
  width: 65%;
}

.dropzon{
  width: 40px;
  height: 40px;
}

.cmt-txt{
  font-size: 16px;
}

.dropzon .Polaris-DropZone--sizeSmall{
  min-height: 0 !important;
}
.dropzon .Polaris-DropZone-FileUpload--small{
  padding: 10px !important;
}
.feature_btnGrp .Polaris-Button{
  padding: 12px 16px;
}